<template>
  <div>
    <accueil />
    <main id="container">
      <div
        class="section"
        v-for="(project, index) in projects"
        :project="true"
        :bg-color="project.bgcolor"
        :id="'project-' + project.id"
        :key="index"
      >
        <div>
          <div
            :id="'img-project-' + project.id"
            class="preview"
            :style="'background-color: ' + project.bgcolor + ';'"
          >
            <div class="fake-nav">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <img :src="getImgUrl(project.id)" />
            <router-link
              :to="{
                name: 'Projet',
                params: {
                  id: project.id,
                  nom: project.name,
                },
              }"
              class="inside-container"
              :style="
                'background-color: ' +
                  project.bgcolor +
                  '; box-shadow: 0 0 200px 200px' +
                  project.bgcolor +
                  ';'
              "
            >
              <div class="inside">
                <div class="project-presentation">
                  <h1
                    class="project-title"
                    :id="'title-project-' + project.id"
                    v-html="project.name"
                  ></h1>
                  <p
                    class="project-description"
                    v-html="project.description"
                  ></p>
                </div>
                <div class="project-infos">
                  <p class="project-date">{{ project.date }}</p>
                  <p class="project-context">{{ project.context }}</p>
                  <project-tags
                    :project="project"
                    :color="transparent"
                    :direction="'column'"
                  />
                </div>
                <div class="project-arrow">
                  <svg
                    version="1.1"
                    id="down-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="30px"
                    height="34px"
                    viewBox="0 0 30 34"
                    enable-background="new 0 0 30 34"
                    xml:space="preserve"
                  >
                    <g>
                      <polyline
                        fill="none"
                        stroke="#333333"
                        stroke-width="2"
                        points="1.565,18.504 15,31.939 28.435,18.504 	"
                      ></polyline>
                      <line
                        fill="none"
                        stroke="#333333"
                        stroke-width="2"
                        x1="15"
                        y1="30.646"
                        x2="15"
                        y2="0.646"
                      ></line>
                    </g>
                  </svg>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Accueil from "@/components/Accueil.vue"
import ProjectTags from "@/components/ProjectTags.vue"
import projectsData from "@/projects.json"

export default {
  name: "ProjectsList",
  components: {
    Accueil,
    ProjectTags,
  },
  data() {
    return {
      projects: projectsData.projects,
      deviceWidth: 0,
    }
  },
  created() {
    this.deviceWidth = window.innerWidth
  },
  destroyed() {},
  methods: {
    getImgUrl(id) {
      return require("../assets/" + id + "_preview.png")
    },
  },
}
</script>

<style>
#container {
  position: relative;
}

.section {
  display: flex;
  align-items: center;
  width: 70vw;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 80px;
}

.section > div {
  width: 100%;
}

.preview {
  background-color: #fff;
  border: 3px solid #eee;
  border-radius: 6px;
  font-size: 0;
  position: relative;
  margin-bottom: 50px;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;
}

.preview .fake-nav {
  width: 100%;
  height: 24px;
  background-color: #eee;
  z-index: 10;
}

.preview .fake-nav span {
  height: 10px;
  width: 10px;
  background-color: #cbcbcb;
  position: absolute;
  border-radius: 100%;
  top: 5px;
}

.preview .fake-nav span:nth-child(1) {
  left: 4px;
}
.preview .fake-nav span:nth-child(2) {
  left: 20px;
}
.preview .fake-nav span:nth-child(3) {
  left: 36px;
}

@media (min-width: 1000px) {
  .preview .fake-nav {
    height: 28px;
  }

  .preview .fake-nav span {
    height: 12px;
    width: 12px;
    top: 8px;
  }

  .preview .fake-nav span:nth-child(1) {
    left: 6px;
  }
  .preview .fake-nav span:nth-child(2) {
    left: 24px;
  }
  .preview .fake-nav span:nth-child(3) {
    left: 42px;
  }
}

.preview img {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.preview:hover img {
  transform: translate(0, -100%);
}

.preview:hover .inside-container {
  transform: translate(0, 0%);
  opacity: 1;
}

.inside-container {
  font-size: 12px;
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  transform: translate(0, 100%);
  transition: transform 600ms, opacity 600ms;
  opacity: 0;
}

.inside {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  display: grid;
  align-items: center;
  transform: translate(-50%, -50%);
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  grid-column-gap: 2em;
}

@media (max-width: 1000px) {
  .inside-container {
    font-size: 11px;
  }

  .inside {
    width: 80%;
  }
}

@media (max-width: 850px) {
  .inside {
    width: 90%;
  }
}

.preview img {
  transition: transform 600ms;
}

.inside .project-presentation {
  grid-column: 1 / 1;
  text-align: right;
  padding-left: 30px;
}

.inside .project-description {
  font-size: 1.4em;
}

.inside .project-infos .project-context {
  font-family: temeraire, serif;
  font-weight: 600;
  font-style: italic;
  font-size: 1.6em;
}

.inside .project-infos .project-date,
.inside .project-infos .project-tags {
  font-size: 1.2em;
}

.inside .project-arrow {
  text-align: right;
  margin-top: 2.6em;
}

.inside .project-arrow svg {
  transform: rotate(-90deg);
}

@keyframes arrow {
  0% {
    transform: translateX(-1em);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-1em);
  }
}

.inside h1 {
  font-size: 3em;
  line-height: 1em;
  margin-bottom: 0.2em;
  font-weight: 400;
  position: relative;
}

@media (max-width: 700px) {
  .section {
    width: 100vw;
    max-width: 100vw;
    margin-bottom: 0;
  }

  .fake-nav,
  .preview > img {
    display: none;
  }

  .inside-container {
    opacity: 1;
    position: relative;
    box-shadow: #fff 0 0 0 0 !important;
    background-color: transparent !important;
    transform: translate(0, 0);
  }

  .preview {
    overflow: initial;
    border: 0px solid #eee;
    border-radius: 0px;
    min-height: 520px;
    display: flex;
    align-items: center;
  }

  .inside {
    position: relative;
    width: 70%;
    margin: 0 auto;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
    grid-template-columns: 1fr;
    grid-gap: 2em;
  }

  .inside .project-presentation {
    text-align: left;
    padding-left: 0;
  }
}

@media (min-width: 700px) {
  .preview {
    background-color: transparent !important;
  }
}
</style>
