<template>
  <div class="home">
    <projects-list v-bind:projects="projects" />
    <footer-el />
  </div>
</template>

<script>
// @ is an alias to /src
import ProjectsList from "@/components/ProjectsList.vue";
import FooterEl from "@/components/FooterEl.vue";

export default {
  name: "Home",
  components: {
    ProjectsList,
    FooterEl
  }
};
</script>
