<template>
  <ul id="project-tags" :class="'project-tags ' + direction">
    <li
      class="project-tag"
      v-for="(tag, i) in project.tags"
      :key="i"
    >
      {{ tag }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "ProjectTags",
  props: ["project", "color", "direction"],
};
</script>

<style>
.project-tag {
  font-family: fira-mono, monospace;
  border-radius: 2em;
}

.project-tags {
  transition: all 200ms;
  display: flex;
}

.project-tags.column {
  flex-direction: column;
}

.project-tags.row {
  flex-direction: row;
}

.project-tags.row .project-tag {
  padding: 0 1em;
}

.project-tags.row .project-tag + .project-tag {
  margin-left: 1em;
}
</style>
