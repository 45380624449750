<template>
  <div class="accueil-container">
    <div class="accueil">
      <div class="logo">
        <img :src="getImgUrl('elsadlmsdef.png')" />
      </div>
      <h2>développement, design & data visualisation</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accueil",
  methods: {
    getImgUrl(id) {
      return require("../assets/" + id);
    },
  },
};
</script>

<style>
.accueil-container {
  background-color: var(--bg-color);
  padding: 160px 0;
  top: 0;
  text-align: center;
}

.accueil {
    padding: 0 6vw;
}

.accueil h2 {
  font-size: 1.1em;
  font-weight: 400;
  font-family: "stratos-lights";
}

.logo {
  max-width: 355px;
  margin: 0 auto;
  margin-bottom: 10px;
}

@media (min-width: 800px) {
  .logo {
    max-width: 440px;
  }

  .accueil h2 {
    font-size: 1.2em;
  }
}
</style>
