var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('accueil'),_c('main',{attrs:{"id":"container"}},_vm._l((_vm.projects),function(project,index){return _c('div',{key:index,staticClass:"section",attrs:{"project":true,"bg-color":project.bgcolor,"id":'project-' + project.id}},[_c('div',[_c('div',{staticClass:"preview",style:('background-color: ' + project.bgcolor + ';'),attrs:{"id":'img-project-' + project.id}},[_vm._m(0,true),_c('img',{attrs:{"src":_vm.getImgUrl(project.id)}}),_c('router-link',{staticClass:"inside-container",style:('background-color: ' +
                project.bgcolor +
                '; box-shadow: 0 0 200px 200px' +
                project.bgcolor +
                ';'),attrs:{"to":{
              name: 'Projet',
              params: {
                id: project.id,
                nom: project.name,
              },
            }}},[_c('div',{staticClass:"inside"},[_c('div',{staticClass:"project-presentation"},[_c('h1',{staticClass:"project-title",attrs:{"id":'title-project-' + project.id},domProps:{"innerHTML":_vm._s(project.name)}}),_c('p',{staticClass:"project-description",domProps:{"innerHTML":_vm._s(project.description)}})]),_c('div',{staticClass:"project-infos"},[_c('p',{staticClass:"project-date"},[_vm._v(_vm._s(project.date))]),_c('p',{staticClass:"project-context"},[_vm._v(_vm._s(project.context))]),_c('project-tags',{attrs:{"project":project,"color":_vm.transparent,"direction":'column'}})],1),_c('div',{staticClass:"project-arrow"},[_c('svg',{attrs:{"version":"1.1","id":"down-arrow","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","width":"30px","height":"34px","viewBox":"0 0 30 34","enable-background":"new 0 0 30 34","xml:space":"preserve"}},[_c('g',[_c('polyline',{attrs:{"fill":"none","stroke":"#333333","stroke-width":"2","points":"1.565,18.504 15,31.939 28.435,18.504 \t"}}),_c('line',{attrs:{"fill":"none","stroke":"#333333","stroke-width":"2","x1":"15","y1":"30.646","x2":"15","y2":"0.646"}})])])])])])],1)])])}),0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fake-nav"},[_c('span'),_c('span'),_c('span')])}]

export { render, staticRenderFns }