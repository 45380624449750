<template>
  <div id="footer">
    <div id="footer-grid">
      <h2><span>on s'écrit ?</span></h2>
      <ul>
        <li><a href="mailto:hello@elsadelmas.fr">hello@elsadelmas.fr</a></li>
        <li>
          <a href="https://www.linkedin.com/in/elsa-delmas/" target="_blank"
            >linkedin</a
          >
        </li>
        <li>
          <a href="https://twitter.com/elsadlms" target="_blank">twitter</a>
        </li>
        <!-- <li><a href="ressources/CV_Elsa_Delmas.pdf" target="_blank">cv</a></li> -->
      </ul>
    </div>
    <p>
      <a href="https://elsadl.github.io/" target="_blank">
        Par ici pour voir la v1 →</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer",
}
</script>

<style>
#footer {
  background-color: #ffcdd8;
  color: var(--default);
  padding: 120px 0 60px 0;
  margin-top: 200px;
  box-sizing: border-box;
  z-index: -1;
  width: 100%;
}

#footer-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 10vw;
  margin-bottom: 60px;
}

#footer p {
  font-size: 0.95em;
  text-align: center;
  width: 100%;
  display: inline-block;
  margin: 0 auto;
  font-family: stratos-lights;
}

#footer p a {
  text-decoration: underline;
  transition: color 200ms;
}

#footer p a:hover {
  text-decoration: underline;
  transition: color 200ms;
}

#footer li a {
  text-decoration: none;
  font-family: stratos-lights;
  font-size: 1.8em;
  transition: color 200ms;
}

#footer li a:hover {
  color: rgb(150, 150, 150);
}

#footer li + li {
  margin-top: 0.6em;
}

#footer h2 {
  margin: 0;
  font-family: temeraire, serif;
  font-weight: 600;
  font-style: italic;
  font-size: 2.2em;
  display: inline;
  position: relative;
}

#footer h2 span {
  position: absolute;
  right: 0;
}

#footer ul li {
  position: relative;
}

#footer ul li a::before {
  display: block;
  content: "💌";
  position: absolute;
  left: -1.6em;
  opacity: 0;
  transition: opacity 200ms;
}

#footer ul li a:hover,
#footer p a:hover {
  color: #f53836;
}

#footer ul li a:hover::before {
  opacity: 1;
  animation: tremble 400ms infinite;
}

@media (max-width: 1200px) {
  #footer-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 800px) {
  #footer-grid {
    grid-template-columns: 1fr;
    grid-gap: 2em;
  }

  #footer {
    padding: 160px 16vw;
  }

  #footer h2 {
    top: 0;
  }

  #footer h2 span {
    position: relative;
    right: 0;
    border-bottom: 0px solid white;
  }
  #footer p {
    text-align: left;
  }
}

@media (max-width: 700px) {
  #footer {
    margin-top: 0px;
  }
}

@keyframes tremble {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-20deg);
  }

  75% {
    transform: rotate(20deg);
  }
}
</style>
