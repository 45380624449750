<template>
  <div id="app">
    <div :class="isAbout() ? 'hidden' : ''" id="initiales">
      <router-link to="/">
        <img :src="getImgUrl('ed.png')" />
      </router-link>
    </div>
    <div id="nav">
      <router-link to="/about">à propos</router-link>
    </div>
    <router-view :key="$route.path" />
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    getImgUrl(nom) {
      return require("./assets/" + nom)
    },
    isAbout() {
      if (location.pathname == "/about") {
        return true
      }
    },
  },
}
</script>

<style lang="less">
:root {
  --default: #333333;
  --bg-color: white;
  color: var(--default);
}

.home {
  background-color: rgb(255, 255, 255);
}

body {
  background-color: var(--bg-color);
  margin: 0;
}

#app {
  font-family: stratos, sans-serif;
  // font-family: roc-grotesk, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--default);
}

ul li {
  list-style: none;
}

img {
  width: 100%;
}

p {
  font-family: stratos-lights;
  font-size: 1.1em;
  font-weight: 400;
}

#nav {
  position: fixed;
  top: 1em;
  right: 3vw;
  z-index: 200;
}

#initiales {
  position: fixed;
  top: 1em;
  left: 3vw;
  z-index: 200;
  width: 50px;
  transition: opacity 200ms;
}

#initiales.hidden,
#initiales a.router-link-exact-active {
  display: none;
}

#nav a {
  font-family: "stratos-lights";
  font-size: 1.2em;
  transition: opacity 200ms;
}

#nav a.router-link-active {
  cursor: default;
  color: white;
}

#initiales:hover,
#nav a:hover {
  opacity: 0.4;
}

#nav a.router-link-active:hover {
  opacity: 1;
}

#nav a::before {
  content: "⬤";
  display: inline-block;
  position: relative;
  font-size: 0.8em;
  right: 0.5em;
  top: -1px;
}

// #nav a.router-link-active,
// #nav a.router-link-active::before {
//   color: var;
// }

#nav a.router-link-active::before {
  content: "◕ ◡ ◕";
  right: 1em;
}
</style>
